import React, { useState } from "react";
//import stuff needed to get songs from the backend using dispatch
import { useSelector, useDispatch } from "react-redux";
import { getSongs } from "../features/songs/songSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getGoals } from "../features/goals/goalSlice";

const Payees = () => {
  const styles = {
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "20px",
    },
    headerCell: {
      backgroundColor: "#4CAF50",
      color: "#fff",
      padding: "10px",
      textAlign: "center", // Center align the header text
      borderBottom: "2px solid #ddd",
    },
    row: {
      backgroundColor: "#f9f9f9",
      transition: "background-color 0.3s",
    },
    rowHover: {
      backgroundColor: "#f1f1f1",
    },
    cell: {
      padding: "10px",
      borderBottom: "1px solid #ddd",
    },
    dropdownRow: {
      backgroundColor: "#f0f0f0",
    },
    dropdownCell: {
      padding: "10px 20px",
      borderBottom: "1px solid #ddd",
    },
    toggleButton: {
      cursor: "pointer",
      padding: "5px 10px",
      backgroundColor: "#4CAF50",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      outline: "none",
    },
  };

  //   const songs = [
  //     {
  //       albumName: 'Album One',
  //       songName: 'Song A',
  //       artists: 'Artist One, Artist Two',
  //       payees: [
  //         { fullName: 'John Doe', email: 'john@example.com', splitPercentage: 50 },
  //         { fullName: 'Jane Smith', email: 'jane@example.com', splitPercentage: 25 },
  //       ],
  //     },
  //     {
  //       albumName: 'Album Two',
  //       songName: 'Song B',
  //       artists: 'Artist Three',
  //       payees: [
  //         { fullName: 'Alice Johnson', email: 'alice@example.com', splitPercentage: 30 },
  //         { fullName: 'Bob Brown', email: 'bob@example.com', splitPercentage: 70 },
  //       ],
  //     },
  //   ];

  const songs = useSelector((state) => state.songs.songs);
  const goals = useSelector((state) => state.goals.goals);


  //need the user to see if they are an admin
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [songsWithPayees, setSongsWithPayees] = useState([]);

  const [unresolvedSongsWithPayees, setUnresolvedSongsWithPayees] = useState(
    []
  );

  const [toggleStatus, setToggleStatus] = useState("all");

  const [currentSongs, setCurrentSongs] = useState([]);

  useEffect(() => {
    dispatch(getSongs());
    dispatch(getGoals());
  }, []);

  // useEffect(() => {
  //   dispatch(getGoals());
  // }, []);

  useEffect(() => {
    if (songs && goals) {
      const songsWithPayees = songs.filter(
        (song) =>
          song.payeeInfo?.payees?.length > 0 &&
          // and song.albumId is not in goals
          goals.some((goal) => goal.albumId === song.albumId)
      );
      setSongsWithPayees(songsWithPayees);

      const unresolvedSongsWithPayees = songsWithPayees.filter(
        (song) => song.payeeInfo.isEntered === false
      );
      setUnresolvedSongsWithPayees(unresolvedSongsWithPayees);

      if (toggleStatus === "unresolved") {
        setCurrentSongs(unresolvedSongsWithPayees);
      } else {
        setCurrentSongs(songsWithPayees);
        console.log("songsWithPayees: ", songsWithPayees);
      }
    }
  }, [songs, goals, toggleStatus]);

  const [openDropdowns, setOpenDropdowns] = useState([]);

  const toggleDropdown = (index) => {
    setOpenDropdowns((prevOpenDropdowns) =>
      prevOpenDropdowns.includes(index)
        ? prevOpenDropdowns.filter((i) => i !== index)
        : [...prevOpenDropdowns, index]
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: "20px",
        }}
      >
        {/* add invisible button for flex */}
        <button style={{ visibility: "hidden" }}>
          {toggleStatus === "all" ? "Show Unresolved" : "Show All"}
        </button>
        <h1>Payees</h1>
        {/* add button to toggle between toggleStatus all and unresolved */}
        <button
          style={styles.toggleButton}
          onClick={() => {
            if (toggleStatus === "all") {
              setToggleStatus("unresolved");
            } else {
              setToggleStatus("all");
            }
          }}
        >
          {toggleStatus === "all" ? "Show Unresolved" : "Show All"}
        </button>
      </div>
      <table style={styles.table}>
        <thead>
          <tr>
            {/* <th style={styles.headerCell}>Album Name</th> */}
            <th style={styles.headerCell}>Song Name</th>
            <th style={styles.headerCell}>Artists</th>
            <th style={styles.headerCell}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {songsWithPayees.length === 0 && (
            <tr>
              <td colSpan="4" style={styles.cell}>
                No songs with payees found
              </td>
            </tr>
          )}
          {currentSongs.map((song, index) => (
            <React.Fragment key={index}>
              <tr
                style={
                  index % 2 === 0
                    ? styles.row
                    : { ...styles.row, ...styles.rowHover }
                }
              >
                {/* <td style={styles.cell}>{song.albumId}</td> */}
                <td style={styles.cell}>{song.name}</td>
                <td style={styles.cell}>
                  {song.artists.map((artist) => artist.artistName).join(", ")}
                </td>
                <td style={styles.cell}>
                  <button
                    style={styles.toggleButton}
                    onClick={() => toggleDropdown(index)}
                  >
                    {openDropdowns.includes(index)
                      ? "Hide Payees"
                      : "Show Payees"}
                  </button>
                  {/* If the user is an admin, show checkbox to mark the split as resolved */}
                  {user && user.role === "admin" && (
                    <label style={{ marginLeft: "10px" }}>
                      <input
                        type="checkbox"
                        // put the value of the checkbox as song.payeeInfo.isEntered
                        // put the onChange function as a function that will update the song.payeeInfo.isEntered to the value of the checkbox
                        checked={song.payeeInfo.isEntered}
                        onChange={(e) => {
                          // make a put request to the song with the new value of song.payeeInfo.isEntered
                          const config = {
                            headers: {
                              Authorization: `Bearer ${user.token}`,
                              "Content-Type": "application/json",
                            },
                          };
                          axios.put(
                            "/api/songs/" + song._id,
                            {
                              payeeInfo: {
                                isEntered: e.target.checked,
                                payees: song.payeeInfo.payees,
                              },
                            },
                            config
                          );
                          //refresh
                          window.location.reload(false);
                        }}
                      />
                      Resolved
                    </label>
                  )}
                </td>
              </tr>
              {openDropdowns.includes(index) && (
                <tr style={styles.dropdownRow}>
                  <td colSpan="4" style={styles.dropdownCell}>
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th style={styles.headerCell}>Full Name</th>
                          <th style={styles.headerCell}>Email Address</th>
                          <th style={styles.headerCell}>Split Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {song.payeeInfo.payees.map((payee, payeeIndex) => (
                          <tr key={payeeIndex}>
                            <td style={styles.cell}>{payee.name}</td>
                            <td style={styles.cell}>{payee.email}</td>
                            <td style={styles.cell}>{payee.percentage}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Payees;
